<template>
  <div>
    <div class="edit-experience-wrapper" v-for="(text, index) in temporaryList" :key="index">
      <el-row type="flex">
        <el-col>
          <div class="edit-title">经历信息{{ index + 1 || '' }}</div>
        </el-col>
        <el-col :span="2">
          <el-popconfirm title="确定删除该记录信息吗" @confirm="deleteJobIntention(index)">
            <el-button slot="reference" type="danger" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>
        </el-col>
      </el-row>
      <div class="information">
        <!-- 经历信息的表单区域 -->
        <el-form label-width="130px" :rules="rules" ref="temporary" :model="temporaryList[index]">
          <el-row type="flex">
            <el-col>
              <el-form-item label="名称" prop="job_title">
                <el-input placeholder="请填写名称" v-model="text.job_title"></el-input>
              </el-form-item>
            </el-col>
            <el-col></el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item label="时间" prop="start_time">
                <el-date-picker
                  type="month"
                  placeholder="请选择开始时间"
                  value-format="yyyy-MM"
                  v-model="text.start_time"
                  @change="startTime(index, text.start_time)"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <div class="soFarText">至</div>
            <el-col>
              <el-form-item prop="end_time" class="endTime">
                <el-date-picker
                  v-model="text.end_time"
                  type="month"
                  placeholder="请选择结束时间"
                  value-format="yyyy-MM"
                  @change="endTime(index, text.end_time)"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="经历描述" prop="description">
              <el-col>
                <el-input
                  type="textarea"
                  placeholder="请填写您的经历描述"
                  v-model="text.description"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
        <!-- 经历信息的表单区域 -->
      </div>
    </div>
    <div class="BottomButton">
      <div class="add-box">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="isLoading"
          v-if="temporaryList.length"
        >
          保存
        </el-button>
      </div>
      <div>
        <el-button type="warning" @click="onAddExperienceFn">添加经历</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      decide: {
        value: null,
        text: '社会经历'
      },
      isLoading: false,
      societyDecide: 1, //0显示用户本地上传的简历信息 1显示已经诊断过的简历信息
      Tmie: '',
      rules: {
        job_title: [{ required: true, message: '请填写名称', trigger: 'change' }],
        start_time: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
        end_time: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
        description: [{ required: true, message: '请描述您的经历', trigger: 'change' }]
      },
      temporaryList: [], //教育经历信息已经存在的数组字段
      form1Valid: [] //判断是否通过兜底校验
    }
  },
  created() {
    this.resumeAnalysisUserInfo != null ? (this.societyDecide = 0) : (this.societyDecide = 1)
    this.getResumeGenerateContentFn()
  },
  computed: {
    ...mapState(['resumeAnalysisUserInfo'])
  },
  methods: {
    ...mapActions(['getResumeGenerateContent', 'resumeGenerateSave']),
    async getResumeGenerateContentFn() {
      const obj = await this.getResumeGenerateContent({ noLoading: false })
      if (this.societyDecide === 0) {
        if (this.resumeAnalysisUserInfo.parsing_result.social_experience.length) {
          this.resumeAnalysisUserInfo.parsing_result.social_experience.forEach(item => {
            item.start_time = item.start_time_year.length
              ? `${item.start_time_year}-${item.start_time_month}`
              : ''
            item.end_time = item.end_time_year.length
              ? `${item.end_time_year}-${item.end_time_month}`
              : ''
          })
        }
        this.temporaryList = JSON.parse(
          JSON.stringify(this.resumeAnalysisUserInfo.parsing_result.social_experience)
        )
      } else {
        if (obj.id) {
          this.temporaryList = obj.social_experience
        }
      }
    },
    //工作时间开始选择
    startTime(index, time) {
      if (time !== null) {
        time = time.split('-')
        this.temporaryList[index].start_time_year = time[0]
        this.temporaryList[index].start_time_month = time[1]
      }
    },
    //工作时间结束选择
    endTime(index, time) {
      if (time !== null) {
        time = time.split('-')
        this.temporaryList[index].end_time_year = time[0]
        this.temporaryList[index].end_time_month = time[1]
      }
    },
    //删除当前经历信息
    deleteJobIntention(index) {
      this.temporaryList.splice(index, 1)
      this.onSubmitFn()
    },
    //添加求职意向
    onAddExperienceFn() {
      this.temporaryList.push({
        subject: '',
        organization_name: '',
        job_title: '',
        school_name: '',
        project_name: '',
        major: '',
        degree: '',
        start_time: '',
        start_time_month: '',
        start_time_year: '',
        end_time: '',
        end_time_month: '',
        end_time_year: '',
        description: ''
      })
    },
    //保存方法
    async onSubmitFn() {
      this.isLoading = true
      this.societyDecide = 1
      let submitObj = {
        social_experience: this.temporaryList
      }
      const res = await this.resumeGenerateSave(submitObj)
      if (res.code === 200) {
        this.$message({
          message: '经历信息保存成功',
          type: 'success'
        })
        this.getResumeGenerateContentFn()
      } else {
        this.$message.error('保存失败,请重试')
      }
      this.isLoading = false
    },
    //保存按钮
    async onSubmit() {
      this.form1Valid = []
      if (this.temporaryList.length) {
        this.$nextTick(async () => {
          this.$refs.temporary.forEach((item, index) => {
            this.$refs.temporary[index].validate(valid => {
              if (valid) {
                this.form1Valid.push(valid)
              } else {
                this.form1Valid.push(valid)
              }
            })
          })
          // form表单全部通过校验
          if (!this.form1Valid.includes(false)) {
            this.onSubmitFn()
          }
        })
      }
    },
    //点击预览简历触发校验
    onSubmitOk() {
      this.form1Valid = []
      if (this.temporaryList.length) {
        this.$nextTick(async () => {
          this.$refs.temporary.forEach((item, index) => {
            this.$refs.temporary[index].validate(valid => {
              if (valid) {
                this.form1Valid.push(valid)
              } else {
                this.form1Valid.push(valid)
              }
            })
          }) // form表单全部通过校验
          if (!this.form1Valid.includes(false)) {
            this.decide.value = true
          } else {
            this.decide.value = false
          }
        })
      } else {
        this.decide.value = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.edit-experience-wrapper
  .edit-title
    font-size 42rpx
    font-weight bold
    color rgb(253, 187, 79)
    margin-bottom 10px
  .information
    margin-top 8px
  .el-textarea
    height auto
    :deep(.el-textarea__inner)
      height 100px
  .soFarText
    font-size 14px
    color #606266
    line-height 40px
    margin-left -378px
  >>> .el-form-item.endTime
    margin-left -105px
.BottomButton
  display flex
  margin-left 560px
  margin-top 30px
  .add-box
    margin-right 20px
</style>
