<template>
  <div>
    <!-- 学籍信息得表单区域 -->
    <el-form label-width="130px" :model="submitObj" ref="submitObj">
      <el-row type="flex">
        <el-col>
          <el-form-item label="学校名称" prop="school_name">
            <el-input placeholder="请填写学校" v-model="submitObj.school_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="学校类型" prop="school_type">
            <el-select placeholder="请选择学校类型" v-model="submitObj.school_type">
              <el-option
                v-for="item in schoolTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                @click.native="schoolTypeClick(item)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item label="所学专业" prop="major">
            <el-input placeholder="请填写专业" v-model="submitObj.major"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="学历" prop="degree">
            <el-select placeholder="请选择学历" v-model="submitObj.degree">
              <el-option
                v-for="item in degreeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                @click.native="degreeClick(item)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item label="入学时间" prop="recent_graduate_year">
            <el-date-picker
              value-format="yyyy"
              type="year"
              placeholder="选择毕业时间"
              v-model="submitObj.recent_graduate_year"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="政治面貌" prop="political_status">
            <el-select placeholder="请选择政治面貌" v-model="submitObj.political_status">
              <el-option
                v-for="item in politicalList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                @click.native="politicalClick(item)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="BottomButton">
        <div class="add-box">
          <el-button type="primary" @click="onSubmit" :loading="isLoading">保存</el-button>
        </div>
      </div>
    </el-form>
    <!-- 学籍信息得表单区域 -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      isLoading: false,
      schoolDecide: 1, //0显示用户本地上传的简历信息 1显示已经诊断过的简历信息
      // rules: {
      //   degree: [{ required: true, message: '请选择学历', trigger: 'change' }],
      //   school_name: [{ required: true, message: '请输入毕业学校', trigger: 'change' }],
      //   school_type: [{ required: true, message: '请选择学校类型', trigger: 'change' }],
      //   major: [{ required: true, message: '请输入所学专业', trigger: 'blur' }],
      //   school_name: [{ required: true, message: '请输入毕业学校', trigger: 'blur' }],
      //   recent_graduate_year: [{ required: true, message: '请选择毕业时间', trigger: 'change' }],
      //   political_status: [{ required: true, message: '请选择政治面貌', trigger: 'change' }]
      // },
      //学校类型
      schoolTypeList: [
        {
          label: '985',
          value: 1
        },
        {
          label: '211',
          value: 2
        },
        {
          label: '普通院校',
          value: 3
        }
      ],
      //学历
      degreeList: [
        {
          label: '其他',
          value: 0
        },
        {
          label: '专科',
          value: 1
        },
        {
          label: '本科',
          value: 2
        },
        {
          label: '硕士',
          value: 3
        },
        {
          label: '博士',
          value: 4
        }
      ],
      //政治面貌
      politicalList: [
        {
          label: '党员',
          value: 0
        },
        {
          label: '团员',
          value: 1
        },
        {
          label: '群众',
          value: 2
        }
      ],
      //学籍信息字段
      submitObj: {}
    }
  },
  created() {
    this.resumeAnalysisUserInfo != null ? (this.schoolDecide = 0) : (this.schoolDecide = 1)
    if (this.resumeAnalysisUserInfo != null) {
      if (this.resumeAnalysisUserInfo.parsing_result.basic_info.degree === '本科')
        this.resumeAnalysisUserInfo.parsing_result.basic_info.degree = 2
      if (this.resumeAnalysisUserInfo.parsing_result.basic_info.degree === '其他')
        this.resumeAnalysisUserInfo.parsing_result.basic_info.degree = 0
      if (this.resumeAnalysisUserInfo.parsing_result.basic_info.degree === '专科')
        this.resumeAnalysisUserInfo.parsing_result.basic_info.degree = 1
      if (this.resumeAnalysisUserInfo.parsing_result.basic_info.degree === '硕士')
        this.resumeAnalysisUserInfo.parsing_result.basic_info.degree = 3
      if (this.resumeAnalysisUserInfo.parsing_result.basic_info.degree === '博士')
        this.resumeAnalysisUserInfo.parsing_result.basic_info.degree = 4
      if (this.resumeAnalysisUserInfo.parsing_result.basic_info.degree === '大专')
        this.resumeAnalysisUserInfo.parsing_result.basic_info.degree = 1
    }
    this.getResumeGenerateContentFn()
  },
  computed: {
    ...mapState(['resumeAnalysisUserInfo'])
  },
  methods: {
    ...mapActions(['getResumeGenerateContent', 'resumeGenerateSave']),

    async getResumeGenerateContentFn() {
      const obj = await this.getResumeGenerateContent({ noLoading: false })
      if (obj.id) {
        // 学籍信息
        if (obj.school_type.length === 2) {
          obj.school_type = ['985']
        }
        this.submitObj = {
          //毕业学校
          school_name: this.schoolDecide
            ? obj.school_name
            : (this.resumeAnalysisUserInfo.parsing_result.education_experience.length &&
                this.resumeAnalysisUserInfo.parsing_result.education_experience[0].school_name) ||
              '',
          //学校类型
          school_type: this.schoolDecide
            ? obj.school_type
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.school_type || [],
          //所学专业
          major: this.schoolDecide
            ? obj.major
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.major || '',
          //学历
          degree: this.schoolDecide
            ? obj.degree
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.degree || 0,
          degree_str: obj.degree_str || '',
          //毕业时间
          recent_graduate_year: this.schoolDecide
            ? obj.recent_graduate_year
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.recent_graduate_year || '',
          //政治面貌
          political_status: this.schoolDecide
            ? obj.political_status
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.political_status || ''
        }
      }
    },
    //选择学校类型
    schoolTypeClick(item) {
      if (item.label === '985') {
        this.submitObj.school_type = [item.label, '211']
      } else {
        this.submitObj.school_type = [item.label]
      }
    },
    //选择学历
    degreeClick(item) {
      this.submitObj.degree_str = item.label
    },
    //选择政治面貌
    politicalClick(item) {
      this.submitObj.political_status = item.label
    },
    // 保存按钮
    async onSubmit() {
      this.isLoading = true
      this.schoolDecide = 1
      const res = await this.resumeGenerateSave(this.submitObj)
      if (res.code === 200) {
        this.$message({
          message: '学籍信息保存成功',
          type: 'success'
        })
        this.getResumeGenerateContentFn()
      } else {
        this.$message.error('保存失败,请重试')
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.BottomButton
  display flex
  margin-left 560px
  margin-top 30px
  .add-box
    margin-right 20px
/deep/ .el-button.empty.el-button--warning
  margin-left 20px
</style>
