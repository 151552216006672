<template>
  <div id="collapse">
    <resumeHeadS
      title="在线编辑简历"
      :zone="zone"
      @resumePreview="resumePreview"
      @resetForm="resetForm"
    />
    <div class="title-img">
      <img src="@/assets/image/resumeLogin/banner.png" alt="" />
    </div>
    <div class="typeArea">
      <el-card class="box-card">
        <!-- 选择简历模板 -->
        <el-drawer :visible.sync="drawer" :with-header="false" direction="ttb" size="50%">
          <div class="drawer-text">请选择一个在线简历模板</div>
          <div class="drawer-list" v-if="onlineResumeList.length">
            <div
              class="list-box"
              v-for="(item, index) in onlineResumeList"
              :key="index"
              @click="select(item)"
              :class="{ active: index === indexOK }"
            >
              <div class="box-baseimg" v-show="index === indexOK">
                <img src="@/assets/image/resumeLogin/勾选.png" alt="" />
              </div>
              <img :src="item.img" alt="" class="pdf-img" />
            </div>
          </div>
          <div class="drawer-baseBt">
            <span class="ensure" @click="ensure">确定</span>
            <span class="call" @click="drawer = false">取消</span>
          </div>
        </el-drawer>
        <!-- 选择简历模板 -->
        <!-- 上传本地简历 -->
        <div class="uploadResume-box">
          <div class="uploadResume">
            <el-upload
              ref="fileUp"
              :action="`${youPaiYunUrl}/interview-tzkj`"
              :data="upyunData"
              :drag="true"
              accept=".doc,.docx,application/pdf"
              :before-upload="beforeAvatarUpload"
              :on-success="onSuccess"
              :on-error="onError"
              :limit="limit"
              class="upload-demo"
              multiple
            >
              <div class="el-upload-img">
                <img src="@/assets/image/resumeLogin/添加icon.png" alt="" />
              </div>
              <div class="el-upload__text">将文件拖到此处，或点击上传</div>
              <div class="el-upload__text two">
                简历解析内容，建议使用PDF文件，也支持DOC，DOCX格式
              </div>
              <div class="el-upload__text three">文件大小不超过10M</div>
            </el-upload>
          </div>
          <!-- 扫码上传简历并解析内容 -->
          <div class="scan-box">
            <div v-if="qrCodeInfo" class="scan-img">
              <img :src="qrCodeInfo.qrcode" alt="" />
            </div>
            <div class="scan-text">扫码上传简历并解析内容</div>
          </div>
          <!-- 扫码上传简历并解析内容 -->
        </div>
        <!-- 预览简历 -->
        <div class="Title">
          个人信息
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <editMeInfo ref="editMeInfo" />
        <div class="cutoffRule"></div>
        <div class="Title">
          求职意向
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <editCareerObjective ref="editCareerObjective" />
        <div class="cutoffRule"></div>
        <div class="Title">
          教育经历
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <editEducation ref="editEducation" />
        <div class="cutoffRule"></div>
        <!-- <div class="Title">学籍信息</div>
        <editStudentStatusInfo ref="editStudentStatusInfo" /> -->
        <!-- <div class="cutoffRule"></div>
        <div class="Title">就业信息</div> -->
        <!-- <editJobInfo ref="editJobInfo" /> -->
        <!-- <div class="cutoffRule"></div> -->
        <div class="Title">
          工作经历
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <editExperience ref="editExperience" />
        <!-- <div class="cutoffRule"></div>
        <div class="Title">社会经历</div>
        <editSocial ref="editSocial" /> -->
        <div class="cutoffRule"></div>
        <div class="Title">
          项目经历
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <editProject ref="editProject" />
        <div class="cutoffRule"></div>
        <div class="Title">
          培训经历
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <editTraining ref="editTraining" />
        <div class="cutoffRule"></div>
        <div class="Title">
          相关技能
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <editSkill ref="skillDecide" />
        <!-- <editSkill
          v-if="skillDecide == 0 && skillDecideSet"
          :temporaryList="skillDecideSet"
          fields="skills_full"
          ref="skillDecideTwo"
          @formRmoveFn="formRmoveFn"
          skill="1"
        /> -->
        <!-- <div class="cutoffRule"></div>
        <div class="Title">商业技能</div>
        <editSkill
          v-if="businessDecide && obj"
          :temporaryList="obj.business_skills_full || []"
          fields="business_skills_full"
          ref="businessDecide"
        />
        <editSkill
          v-if="businessDecide == 0 && businessDecideSet"
          fields="business_skills_full"
          :temporaryList="businessDecideSet"
          ref="businessDecideTwo"
        />
        <div class="cutoffRule"></div>
        <div class="Title">语言技能</div>
        <editSkill
          v-if="languageDecide && obj"
          :temporaryList="obj.language_skills_full || []"
          fields="language_skills_full"
          ref="languageDecide"
        />
        <editSkill
          v-if="languageDecide == 0 && languageDecideSet"
          :temporaryList="languageDecideSet"
          fields="language_skills_full"
          ref="languageDecideTwo"
        />-->
        <div class="cutoffRule"></div>
        <div class="Title">
          荣誉奖项
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <editAwards ref="honorDecide" />
        <!-- <editAwards
          v-if="honorDecide == 0 && honorDecideSet"
          :temporaryList="honorDecideSet"
          fields="awards_full"
          ref="honorDecideTwo"
          @formRmoveFn="formRmoveFn"
        /> -->
        <!-- <div class="cutoffRule"></div> -->
        <!-- <div class="Title">技能证书</div>
        <editAwards
          v-if="certificateDecide && obj"
          :temporaryList="obj.certificate_full || []"
          fields="certificate_full"
          ref="certificateDecide"
        />
        <editAwards
          v-if="certificateDecide == 0 && certificateDecideSet"
          :temporaryList="certificateDecideSet"
          fields="certificate_full"
          ref="certificateDecideTwo"
        /> -->
        <div class="cutoffRule"></div>
        <div class="Title">
          自我评价
          <!-- <el-popover
            placement="top-start"
            title="标题"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          >
            <span slot="reference" class="wenhao"></span>
          </el-popover> -->
        </div>
        <el-row>
          <el-form label-width="130px" :model="submitObj" ref="selfEvaluation">
            <el-form-item label="自我评价">
              <el-input type="textarea" v-model="submitObj.self_evaluation"></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <!-- <div class="hint">
          <div>填写小贴士</div>
          <div>1、2年以上房地产税务经验。</div>
          <div>2、熟悉商业地产业务流程和财务核算，能熟练运用财务核算软件和办公软件。</div>
          <div>3、具备良好的沟通能力，有较强文字综合和表达能力。</div>
        </div> -->
        <div class="BottomButton">
          <div class="add-box">
            <el-button @click="submitForm" :loading="isLoading">保存</el-button>
          </div>
        </div>
        <!-- 完善更多信息 -->
        <!-- <div class="cutoffRule"></div> -->
        <el-collapse @change="distanceFn" v-model="activeNames">
          <el-collapse-item title="完善更多信息" name="1">
            <editPerfect style="margin-top: 30px" ref="editPerfect" />
          </el-collapse-item>
        </el-collapse>
        <!-- 结尾 -->
        <!-- <div class="cutoffRule"></div> -->
        <div class="updateTime" v-if="obj && obj.update_time">
          简历更新时间 {{ obj.update_time }}
        </div>
      </el-card>
    </div>
    <!-- 菜单弹窗组件 -->
    <MenuStructure class="menuStructure" @saveSettings="saveSettings" type="/resume/edit" />
    <!-- 生成简历文件流-默认选择简历模板1 -->
    <div style="position: absolute; left: -9999px; top: -9999px">
      <preview1 />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import editMeInfo from './editMeInfo'
import editCareerObjective from './editCareerObjective'
import editStudentStatusInfo from './editStudentStatusInfo'
import editJobInfo from './editJobInfo'
import editExperience from './editExperience'
import editEducation from './editEducation'
import editSocial from './editSocial'
import editProject from './editProject'
import editTraining from './editTraining'
import editSkill from '@/components/editSkill'
import editAwards from '@/components/editAwards'
import editPerfect from '@/views/resume/edit/editPerfect/index'
import MenuStructure from '@/components/MenuStructure'
import { upyunUrl, youPaiYunUrl } from '@/api/config'
import preview1 from '@/views/resume/pdfExport/preview1'
import resumeHeadS from '@/components/resumeHeadS.vue'

export default {
  inject: ['reload'],
  components: {
    editMeInfo, //个人信息组件
    editCareerObjective, //求职意向组件
    editStudentStatusInfo, //学籍信息组件
    editJobInfo, //就业信息
    editExperience, //工作经历
    editEducation, //教育经历
    editSocial, //社会经历
    editProject, //项目经历
    editTraining, //培训经历
    editSkill, //技能
    editAwards, //奖项
    editPerfect, //完善更多信息
    MenuStructure, //菜单弹窗
    preview1, //简历模板一
    resumeHeadS //头部组件
  },
  data() {
    return {
      resumeName: null, //简历名称
      timers: null, //定时器
      activeNames: ['1'],
      onlineResumeList: [], //在线简历数据
      isIndex: 0, //选择的模板下标
      indexOK: 0, //给选择的模板加边框样式
      drawer: false, //控制预览简历得下拉框
      pageS: 1, //1代表跳转到pdf导出页面  2代表跳转到简历诊断页面
      decide: {
        value: null,
        text: '自我评价'
      },
      loaDing: '', //点击预览简历开始loding
      verifySet: [], //全部为true才能去预览简历
      mistake: [], //当前没有通过校验的信息
      objDataSet: null, //所有字段集合
      isLoading: false,
      skillDecide: 1, //1显示在线简历 0显示本地简历 技能
      //技能
      skillDecideSet: [
        {
          description: '', //技能描述
          level: '', //掌握程度
          name: '', //名字
          time: '' //掌握时长
        }
      ], //技能数据  本地简历
      // businessDecide: 1, //1显示在线简历 0显示本地简历 商业技能
      //商业技能
      businessDecideSet: [
        {
          description: '', //技能描述
          level: '', //掌握程度
          name: '', //名字
          time: '' //掌握时长
        }
      ], //商业技能  本地简历
      languageDecide: 1, //1显示在线简历 0显示本地简历 语言技能
      //语言技能
      languageDecideSet: [
        {
          description: '', //技能描述
          level: '', //掌握程度
          name: '', //名字
          time: '' //掌握时长
        }
      ], //语言技能  本地简历
      honorDecide: 1, //荣誉奖项
      honorDecideSet: [
        {
          name: '',
          time: ''
        }
      ], //荣誉奖项 本地简历
      certificateDecide: 1, //技能证书
      certificateDecideSet: [
        {
          name: '',
          time: ''
        }
      ], //技能证书 本地简历
      selfDecide: 1, //自我评价
      loading: true,
      youPaiYunUrl,
      upyunData: { policy: '', signature: '' },
      limit: 1,
      // rules: {
      //   self_evaluation: [{ required: true, message: '请对自我做出评价', trigger: 'change' }]
      // },
      obj: null, //简历信息
      submitObj: {
        all_result_n: null,
        id: 0,
        self_evaluation: '' // 自我评价
      },
      qrCodeInfo: null, // 生成简历二维码参数
      zone: true //头部组件功能显示
    }
  },
  computed: {
    ...mapState(['userInfo', 'currentResumeEditId', 'currentResumeInfo'])
  },
  created() {
    this.getShareQrcodeFn()
    // if (JSON.stringify(this.$route.query) !== '{}') {
    //   this.$router.push({ query: {} })
    //   this.whetherFn()
    // }
    this.getOnlineResumeLisFn()
    if (JSON.stringify(this.$route.query) === '{}') {
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.id) {
      this.submitObj.id = this.$route.query.id
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.type && this.$route.query.type === 'add') {
    }
    this.getUpYunSignFn()
  },
  methods: {
    ...mapActions([
      'resumeFileUpyun',
      'getResumeGenerateContent',
      'resumeGenerateSave',
      'getUpYunSign',
      'getResumeAnalysisContent',
      'getResumeUploading',
      'rmoverResumeUploading',
      'getOnlineResumeList',
      'getShareQrcode'
    ]),
    ...mapMutations({
      setToken: 'SET_TOKEN',
      removerContents: 'REMOVER_CONTENTS',
      setCurrentResumeEditId: 'SET_CURRENT_RESUME_EDIT_ID',
      setCurrentResumeInfo: 'SET_CURRENT_RESUME_INFO'
    }),
    async getResumeGenerateContentFn() {
      clearInterval(this.resumeTims)
      if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
      this.obj = await this.getResumeGenerateContent({ id: this.submitObj.id })
      if (this.obj && this.obj.id) {
        this.submitObj.id = this.obj.id
        this.submitObj.self_evaluation = this.obj.self_evaluation || ''
      }
    },
    // 获取简历二维码参数
    async getShareQrcodeFn() {
      const info = sessionStorage.getItem('qrCodeInfo')
      if (info) {
        this.qrCodeInfo = JSON.parse(info)
      } else {
        const data = await this.getShareQrcode()
        if (data.data) {
          this.qrCodeInfo = data.data
          sessionStorage.setItem('qrCodeInfo', JSON.stringify(data.data))
        }
      }
      //进入页面开启定时器
      this.scanFn()
    },
    // 获取在线模板简历列表
    async getOnlineResumeLisFn() {
      this.onlineResumeList = await this.getOnlineResumeList()
    },
    //点击打开预览简历弹窗
    pulldown() {
      this.drawer = true
    },
    //自我评价保存按钮
    async submitForm() {
      this.isLoading = true
      if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
      const res = await this.resumeGenerateSave(this.submitObj)

      if (res.code === 200) {
        this.selfDecide = 1
        this.$message({
          message: '自我评价保存成功',
          type: 'success'
        })
      } else {
        this.$message.error('保存失败,请重试')
      }
      this.isLoading = false
    },
    //点击预览简历触发自我评价校验
    onSubmitOk() {
      this.$refs.selfEvaluation.validate(valid => {
        if (valid) {
          this.decide.value = true
          this.verifySet.push(this.decide)
        } else {
          this.decide.value = false
          this.verifySet.push(this.decide)
        }
      })
    },
    //退出登录按钮
    confirm() {
      this.removerContents()
      this.$router.push('/resume/login')
    },
    //文件上传成功调用接口获取最新数据
    async onSuccess(response, file, fileList) {
      const loading = this.$loading({
        lock: true,
        text: '解析您的简历中,请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const obj = {
        resume_url: upyunUrl + file.response.url,
        file_name: file.name
      }
      const res = await this.getResumeAnalysisContent(obj)
      if (res) {
        if (res.errorcode === 0) {
          //如果不为空说明上传得是英文简历
          if (JSON.stringify(res.english_parsing_result) !== '{}') {
            res.parsing_result = res.english_parsing_result
          }
          this.setCurrentResumeInfo(res)
          if (res.parsing_result) {
            // 其他信息判断
            if (res.parsing_result.others && res.parsing_result.others.self_evaluation) {
              this.submitObj.self_evaluation = res.parsing_result.others.self_evaluation // 自我评价
            } else {
              this.submitObj.self_evaluation = ''
            }
          }
        } else {
          if (res.errorcode === 13) {
            this.$message.error('解析过于频繁')
          } else if (res.errorcode === 80) {
            this.$message.error('简历解析文件错误')
          } else if (res.errorcode === 81) {
            this.$message.error('英文简历解析件错误')
          } else if (res.errorcode === 90) {
            this.$message.error(res.errormessage)
          } else if (res.errorcode === 91) {
            this.$message.error('不支持图片文件解析')
          } else if (res.errorcode === 92) {
            this.$message.error('文件不是真实简历')
          } else {
            this.$message.error('解析错误')
          }
          this.resetForm()
        }
        setTimeout(() => {
          loading.close()
        }, 3000)
      }
      this.$refs.fileUp.clearFiles()
    },
    // 当用户扫码上传简历
    scanFn() {
      clearTimeout(this.timers)
      this.timers = setTimeout(async () => {
        const res = await this.getResumeUploading({
          scene: this.qrCodeInfo.scene
        })
        if (res && res.code === 200 && res.data) {
          const file = {
            name: res.data.name,
            response: {
              url: res.data.url
            }
          }
          this.rmoverResumeUploading({ scene: this.qrCodeInfo.scene })
          this.onSuccess('', file, '')
        } else {
          this.scanFn()
        }
      }, 3000)
    },
    //文件上传失败
    onError() {
      this.$message({
        message: '上传失败,请重试',
        type: 'warning'
      })
    },
    //校验文件大小
    beforeAvatarUpload(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$message({
          type: 'error',
          message: '文件大小超出10M，请重新上传！'
        })
        return false
      }
    },
    //预览简历方法和诊断简历方法
    async resumePreview(number) {
      this.pageS = number
      if (number === 2) {
        this.$confirm('此操作会保存当前简历,是否确定继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse',
          type: 'warning'
        })
          .then(async () => {
            await this.resumeSace()
          })
          .catch(() => {
            return
          })
      } else {
        await this.resumeSace()
      }
      // this.$confirm('此操作会保存当前简历,是否确定继续', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(async () => {
      // })
    },
    //诊断简历的按钮
    // diagnosticResume() {
    //   this.$confirm('此操作会保存当前简历,是否确定继续', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(async () => {
    //     this.pageS = 2
    //     await this.resumeSace()
    //   })
    // },
    //获取所有组件实例字段 判断校验
    async resumeSace() {
      this.mistake = []
      await this.checkResult()
      this.verifySet.forEach(item => {
        if (item.value === false) {
          return this.mistake.push(item)
        }
      })
      this.mistakeData()
    },
    //mistake数组存在有数据的情况 代表不能通过
    async mistakeData() {
      if (this.mistake.length) {
        return this.$message.error(`请完善${this.mistake[0].text}部分`)
      } else {
        await this.integration()
        await this.getResumeGenerateContentFn()
        this.loaDing.close()
        if (this.pageS === 1) {
          this.drawer = true
        } else {
          // 当用户通过校验存入最新的字段时候进行文件流上传
          await this.toGetPdf()
        }
      }
    },
    //获取所有组件校验后的结果
    async checkResult() {
      this.verifySet = []
      // const decide = {
      //   value: true,
      //   text: '技能信息'
      // }
      // const languageSetDecide = {
      //   value: true,
      //   text: '荣誉奖项'
      // }
      this.$refs.editMeInfo.onSubmitOk() //获取个人信息的校验结果
      this.verifySet.push(this.$refs.editMeInfo.decide)
      this.$refs.editCareerObjective.onSubmitOk() //获取求职意向检验结果
      this.verifySet.push(this.$refs.editCareerObjective.decide)
      this.$refs.editExperience.onSubmitOk() //获取工作经历意向检验结果
      this.verifySet.push(this.$refs.editExperience.decide)
      this.$refs.editEducation.onSubmitOk() //获取教育经历检验结果
      this.verifySet.push(this.$refs.editEducation.decide)
      // this.$refs.editSocial.onSubmitOk() //获取社会经历检验结果
      // this.verifySet.push(this.$refs.editSocial.decide)
      this.$refs.editProject.onSubmitOk() //获取项目经历检验结果
      this.verifySet.push(this.$refs.editProject.decide)
      this.$refs.editTraining.onSubmitOk() //获取培训经历检验结果
      this.verifySet.push(this.$refs.editTraining.decide)
      //获取技能校验结果
      this.$refs.skillDecide.onSubmitOk()
      this.verifySet.push(this.$refs.skillDecide.decide)

      // if (this.$refs.skillDecide !== undefined) {
      //   this.$refs.skillDecide.onSubmitOk()
      //   this.verifySet.push(this.$refs.skillDecide.decide)
      // } else if (this.$refs.skillDecideTwo !== undefined) {
      //   this.$refs.skillDecideTwo.onSubmitOk()
      //   this.verifySet.push(this.$refs.skillDecideTwo.decide)
      // } else {
      //   this.verifySet.push(decide)
      // }
      //获取商业技能校验结果
      // if (this.$refs.businessDecide !== undefined) {
      //   this.$refs.businessDecide.onSubmitOk()
      //   this.verifySet.push(this.$refs.businessDecide.decide)
      // } else if (this.$refs.businessDecideTwo !== undefined) {
      //   this.$refs.businessDecideTwo.onSubmitOk()
      //   this.verifySet.push(this.$refs.businessDecideTwo.decide)
      // } else {
      //   this.verifySet.push(decide)
      // }
      // //获取语言技能校验结果
      // if (this.$refs.languageDecide !== undefined) {
      //   this.$refs.languageDecide.onSubmitOk()
      //   this.verifySet.push(this.$refs.languageDecide.decide)
      // } else if (this.$refs.languageDecideTwo !== undefined) {
      //   this.$refs.languageDecideTwo.onSubmitOk()
      //   this.verifySet.push(this.$refs.languageDecideTwo.decide)
      // } else {
      //   this.verifySet.push(decide)
      // }
      //获取荣誉奖项的校验结果
      this.$refs.honorDecide.onSubmitOk()
      this.verifySet.push(this.$refs.honorDecide.decide)
      // if (this.$refs.honorDecide !== undefined) {
      //   this.$refs.honorDecide.onSubmitOk()
      //   this.verifySet.push(this.$refs.honorDecide.decide)
      // } else if (this.$refs.honorDecideTwo !== undefined) {
      //   this.$refs.honorDecideTwo.onSubmitOk()
      //   this.verifySet.push(this.$refs.honorDecideTwo.decide)
      // } else {
      //   this.verifySet.push(languageSetDecide)
      // }
      //获取技能证书的校验结果
      // if (this.$refs.certificateDecide !== undefined) {
      //   await this.$refs.certificateDecide.onSubmitOk()
      //   certificateDecide.value = this.$refs.certificateDecide.decide.value
      //   this.verifySet.push(certificateDecide)
      // } else if (this.$refs.certificateDecideTwo !== undefined) {
      //   this.$refs.certificateDecideTwo.onSubmitOk()
      //   certificateDecide.value = this.$refs.certificateDecideTwo.decide.value
      //   this.verifySet.push(certificateDecide)
      // } else {
      //   this.verifySet.push(certificateDecide)
      // }
      //自我评价
      this.onSubmitOk()
    },
    //获取所有组件对象字段
    async integration() {
      this.loaDing = this.$loading({
        lock: true,
        text: '简历保存中,请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      //所有字段集合
      const editMeInfo = this.$refs.editMeInfo.submitObj //个人信息字段
      const editPerfect = this.$refs.editPerfect.submitObj //完善更多信息字段
      const intention = {
        intention: this.$refs.editCareerObjective.temporaryList
      } //求职信息
      // const school_name = this.$refs.editStudentStatusInfo.submitObj //学籍信息
      // const current_status = this.$refs.editJobInfo.submitObj //就业信息
      const editExperience = {
        work_experience: this.$refs.editExperience.temporaryList
      } //工作经历
      const editEducation = {
        education_experience: this.$refs.editEducation.temporaryList
      } //教育经历
      // const editSocial = { social_experience: this.$refs.editSocial.temporaryList } //社会经历
      const editProject = {
        project_experience: this.$refs.editProject.temporaryList
      } //项目经历
      const editTraining = {
        training_experience: this.$refs.editTraining.temporaryList
      } //培训经历
      const skillDecide = { skills_full: [] } //技能
      // const businessDecide = { business_skills_full: [] } //商业技能
      // const languageDecide = { language_skills_full: [] } //语言技能
      const honorDecide = { awards_full: [] } //荣誉奖项
      // const certificateDecide = { certificate_full: [] } //证书
      const self_evaluation = this.submitObj.self_evaluation //自我评价
      // 技能实例
      skillDecide.skills_full = this.$refs.skillDecide.temporaryListBoxList
      // if (this.$refs.skillDecide !== undefined) {
      //   skillDecide.skills_full = this.$refs.skillDecide.temporaryList
      // } else if (this.$refs.skillDecideTwo !== undefined) {
      //   skillDecide.skills_full = this.$refs.skillDecideTwo.temporaryList
      // }
      // 商业技能实例
      // if (this.$refs.businessDecide !== undefined) {
      //   businessDecide.business_skills_full = this.$refs.businessDecide.temporaryList
      // } else if (this.$refs.businessDecideTwo !== undefined) {
      //   businessDecide.business_skills_full = this.$refs.businessDecideTwo.temporaryList
      // }
      // // 语言技能实例
      // if (this.$refs.languageDecide !== undefined) {
      //   languageDecide.language_skills_full = this.$refs.languageDecide.temporaryList
      // } else if (this.$refs.languageDecideTwo !== undefined) {
      //   languageDecide.language_skills_full = this.$refs.languageDecideTwo.temporaryList
      // }
      // //荣誉奖项实例
      honorDecide.awards_full = this.$refs.honorDecide.temporaryListBoxList
      // if (this.$refs.honorDecide !== undefined) {
      //   honorDecide.awards_full = this.$refs.honorDecide.temporaryList
      // } else if (this.$refs.honorDecideTwo !== undefined) {
      //   honorDecide.awards_full = this.$refs.honorDecideTwo.temporaryList
      // }
      //技能证书
      // if (this.$refs.certificateDecide !== undefined) {
      //   certificateDecide.certificate_full = this.$refs.certificateDecide.temporaryList
      // } else if (this.$refs.certificateDecideTwo !== undefined) {
      //   certificateDecide.certificate_full = this.$refs.certificateDecideTwo.temporaryList
      // }
      this.objDataSet = Object.assign(
        editMeInfo,
        editPerfect,
        intention,
        // school_name,
        // current_status,
        editExperience,
        editEducation,
        // editSocial,
        editProject,
        editTraining,
        skillDecide,
        // businessDecide,
        // languageDecide,
        honorDecide,
        // certificateDecide,
        { self_evaluation }
      )
      // 新增一个对象 方便做诊断简历的数据回显
      // const all_result_n = {
      //   cv_name: '', // 系统缓存文件名（!）
      //   ocr_count: 0, // (!)
      //   src_id: '', // 对应招聘网站所属的简历ID，若没有识别出简历来源, 则ID为空（!）
      //   version: '', // 简历解析版本（!）
      //   cv_language: '', // 简历语言（!）
      //   src_site: '', // 简历来源（!）
      //   file_name:
      //     editMeInfo && editMeInfo.name ? `${editMeInfo.name}的个人简历` : '', // 文件名字（!）
      //   errormessage: '', // 解析错误信息（!）
      //   cv_id: '', // 系统缓存简历ID（!）
      //   english_parsing_result: {}, // （!）
      //   updated_time: '', // 简历更新时间，若没有则为空 （!）
      //   applied_job: '', // （!）
      //   resume_integrity: 0, // 简历完整度评分，简历内容约完整，得分越高，分数范围为0-1 （!）
      //   parsing_result: {
      //     project_experience:
      //       editMeInfo && editMeInfo.project_experience
      //         ? editMeInfo.project_experience
      //         : '',
      //     contact_info: {
      //       // 联系方式
      //       phone_number:
      //         editMeInfo && editMeInfo.phone_number
      //           ? editMeInfo.phone_number
      //           : '', // 手机	国内11位手机号（√）
      //       QQ: editMeInfo && editMeInfo.QQ ? editMeInfo.QQ : '', // QQ号	QQ号码（√）
      //       wechat: editMeInfo && editMeInfo.wechat ? editMeInfo.wechat : '', // 微信号 *	微信号码（√）
      //       email: editMeInfo && editMeInfo.email ? editMeInfo.email : '', // 邮箱	合格邮箱地址（√）
      //       home_phone_number:
      //         editMeInfo && editMeInfo.phone_number
      //           ? editMeInfo.phone_number
      //           : '' // 固定电话 *	国内8位座机号（√）
      //     },
      //     social_experience: [
      //       // 社会经历
      //       {
      //         start_time_year: '', // 开始时间年份  4位数年份，2013（√）
      //         start_time_month: '', // 开始时间月份	 2位数月份，03（√）
      //         end_time_year: '', // 结束时间年份   4位数年份，2013（√）
      //         end_time_month: '', // 结束时间月份	2位数月份，11（√）
      //         still_active: 0, // 是否仍在	1/0，1表示仍在其位 （!）
      //         organization_name: '', // 公司/学校/社团名（√）
      //         department: '', // 所属部门（!）
      //         location: '', // 地点（!）
      //         job_title: '', // 职位名（√）
      //         description: '' // 工作描述（√）
      //       }
      //     ],
      //     education_experience:
      //       editEducation && editEducation.education_experience
      //         ? editEducation.education_experience
      //         : [],
      //     basic_info: {
      //       // 基本信息
      //       major:
      //         editEducation &&
      //         editEducation.education_experience.length &&
      //         editEducation.education_experience[0].major
      //           ? editEducation.education_experience[0].major
      //           : '', // 专业	最高学历专业（√）
      //       expect_location:
      //         intention &&
      //         intention.intention.length &&
      //         intention.intention[0].city
      //           ? intention.intention[0].city
      //           : '', // 期望工作地区	期望工作城市或区域，多个地区则以英文逗号分隔，如 北京,上海（√）
      //       current_salary: '', // 当前薪水	当前工资或当前工资范围，如 20000-30000元/月，15-30万人民币等，以简历为准（!）
      //       current_location_norm: '', // 所在地（标准化）	所在地标准化 中国-北京-北京-昌平区，仅支持中国地区（!）
      //       current_location:
      //         editMeInfo && editMeInfo.current_location
      //           ? editMeInfo.current_location
      //           : '', // 所在地	所在地城市或区域，如 北京西城，湖南长沙（!）
      //       expect_location_norm: '', // 期望工作地区（标准化）	期望工作城市或区域，多个地区则以英文逗号分隔，分别为标准化地名，中国-广东-广州,中国-广东-深圳，仅支持中国地区（√）
      //       desired_salary:
      //         intention &&
      //         intention.intention.length &&
      //         intention.intention[0].minwage_name &&
      //         intention.intention[0].maxwage_name
      //           ? `${intention.intention[0].minwage_name}-${intention.intention[0].maxwage_name}`
      //           : '', // 期望薪水	期望工资或期望工资范围，如 2001-2999元/月，10k-12k等，以简历为准（√）
      //       birthplace:
      //         editMeInfo && editMeInfo.birthplace ? editMeInfo.birthplace : '', // 籍贯地区，如 广东广州，洛阳，江苏。以简历描述为准（√）
      //       degree:
      //         editEducation &&
      //         editEducation.education_experience.length &&
      //         editEducation.education_experience[0].degree
      //           ? editEducation.education_experience[0].degree
      //           : '', // 学历	最高学历 博士/MBA/EMBA/硕士/本科/大专/高中/中专/初中（√）
      //       desired_industry:
      //         intention &&
      //         intention.intention.length &&
      //         intention.intention[0].trade_name
      //           ? intention.intention[0].trade_name
      //           : '', // 期望行业	候选人提及期望行业，本字段目前仅支持中文简历提取（√）
      //       desired_position:
      //         intention &&
      //         intention.intention.length &&
      //         intention.intention[0].category_name
      //           ? intention.intention[0].category_name
      //           : '', // 期望职位（√）
      //       zipcode: editMeInfo && editMeInfo.zipcode ? editMeInfo.zipcode : '', // 邮编 *	6位数字邮编，如 510610（√）
      //       professional_level: '', // 候选人专业级别	根据经历评估候选人所在级别，初级/中级/高级/资深，本字段目前仅支持中文简历提取（!）
      //       national_identity_number: '', // 身份证号	中国居民身份证号码（!）
      //       date_of_birth:
      //         editMeInfo && editMeInfo.date_of_birth
      //           ? editMeInfo.date_of_birth
      //           : '', // 生日	出生日期，如 1981-02-01，如只有年月，则为1981-02（√）
      //       birthplace_norm:
      //         editMeInfo && editMeInfo.birthplace ? editMeInfo.birthplace : '', // 籍贯（标准化）	标准化籍贯地区，如 中国-江苏-南京，仅支持中国地区（√）
      //       num_work_experience:
      //         editMeInfo && editMeInfo.num_work_experience
      //           ? editMeInfo.num_work_experience
      //           : '', // 工作经验	当前工作年限，整数（√）
      //       current_position: '', // 当前职位（!）
      //       work_start_year:
      //         editExperience &&
      //         editExperience.work_experience.length &&
      //         editExperience.work_experience[0].start_time_year
      //           ? editExperience.work_experience[0].start_time_year
      //           : '', // 开始工作年限	四位数年份，如2013（√）
      //       political_status: '', // 政治面貌	党员/团员/群众（√）
      //       current_company: '', // 当前公司（√）
      //       school_name:
      //         editEducation &&
      //         editEducation.education_experience.length &&
      //         editEducation.education_experience[0].school_name
      //           ? editEducation.education_experience[0].school_name
      //           : '', // 毕业学校	最高学历学校（√）
      //       ethnic: editMeInfo && editMeInfo.ethnic ? editMeInfo.ethnic : '', // 民族	中国56个民族， 如 汉族，壮族（√）
      //       recent_graduate_year:
      //         editEducation &&
      //         editEducation.education_experience.length &&
      //         editEducation.education_experience[0].start_time_endDate
      //           ? editEducation.education_experience[0].start_time_endDate
      //           : '', // 最近毕业年份	表示候选人最近一段学历毕业的年份，如2020（√）
      //       name: editMeInfo && editMeInfo.name ? editMeInfo.name : '', // 姓名	中文姓名（√）
      //       lastupdate_time: '', // 简历解析完成时间	简历解析请求完成的时间格式为 "2020-10-09-06-23-15"（!）
      //       gender:
      //         editMeInfo && editMeInfo.gender
      //           ? editMeInfo.gender == 1
      //             ? '男'
      //             : '女' || ''
      //           : '', // 性别	男/女（√）
      //       age: editMeInfo && editMeInfo.age ? editMeInfo.age : '', // 年龄	当前年龄，整数（√）
      //       marital_status:
      //         (editMeInfo && editMeInfo.marital_status >= 0) ||
      //         editMeInfo.marital_status
      //           ? editMeInfo.marital_status == 0
      //             ? '未婚'
      //             : editMeInfo.marital_status == 1
      //             ? '已婚'
      //             : editMeInfo.marital_status == 3
      //             ? '离异'
      //             : '再婚' || ''
      //           : '', // 婚姻状况	未婚/已婚（!）
      //       current_status: '', // 求职状态	在职，正在找工作/在职，考虑好的职业机会/在职，暂不考虑其他机会/已离职/应届生（!）
      //       school_type: '', // 毕业院校类别	985 211/211/空值（√）
      //       detailed_location:
      //         editPerfect && editPerfect.detailed_location
      //           ? editPerfect.detailed_location
      //           : '', // 详细地址	具体家庭居住地，如 长沙市雨花区劳动西路1号（√）
      //       industry: '' // 所属行业	最新工作的所属行业（!）
      //     },
      //     training_experience:
      //       editMeInfo && editMeInfo.training_experience
      //         ? editMeInfo.training_experience
      //         : '',
      //     work_experience:
      //       editMeInfo && editMeInfo.work_experience
      //         ? editMeInfo.work_experience
      //         : '',
      //     others: {
      //       // 补充信息
      //       language: [], // 语言	掌握语言技能（√）
      //       certificate: [''], // 证书	所获证书（√）
      //       skills:
      //         skillDecide && skillDecide.skills_full
      //           ? skillDecide.skills_full
      //           : [], // 技能	整合提取后的专业技能列表（√）
      //       self_evaluation:
      //         editMeInfo && editMeInfo.self_evaluation
      //           ? editMeInfo.self_evaluation
      //           : [], // 自我评价	个人评价描述（√）
      //       awards:
      //         honorDecide && honorDecide.awards_full
      //           ? honorDecide.awards_full
      //           : [], // 奖项	所获奖项（√）
      //       it_skills: [], // 计算机技能	整合提取后的计算机技能列表（√）
      //       business_skills: [] // 商业技能	整合提取后的商业技能列表（√）
      //     },
      //     resume_rawtext: '' // 原始文字返回需设置请求查询 rawtext=1, 原始文字变量名为 resume_rawtext，变量类型为 string （!）
      //   },
      //   avatar_url: '', // 简历头像图片所在URL	如简历中头像图片为链接形式，保存头像所在链接URL，没有链接返回空字符串 （!）
      //   client_id: '', // （!）
      //   file_ext: '', // （!）
      //   errorcode: 0, // 错误码	请参照错误码汇总，解析成功则为 0 （!）
      //   avatar_data: '', // 简历头像图片	BASE64格式的简历头像图片，没有头像返回空字符串 （!）
      //   hash_id: '' // （!）
      // }
      let id = 0
      if (this.currentResumeEditId) id = this.currentResumeEditId
      this.objDataSet = Object.assign(this.objDataSet, {
        all_result_n: null,
        id: id
      })
      const res = await this.resumeGenerateSave(this.objDataSet)
      if (res.code === 200) {
        this.$message({
          message: '简历保存成功',
          type: 'success'
        })
      } else {
        this.$message.error('保存失败,请重试')
      }
    },
    //代表用户从编辑简历页面点击菜单去诊断页面  获取所有字段进行赋值 大对象
    async saveSettings() {
      this.resumePreview(2)
      // await this.integration()
      // await this.getResumeGenerateContentFn()
      // await this.toGetPdf()
      // await this.leaveFn()
      // this.loaDing.close()
    },
    //获取简历名字
    getName() {
      this.resumeName = this.currentResumeInfo.resume_name
      if (this.resumeName === null) {
        //拼接导出文件名字
        this.resumeName = `${this.currentResumeInfo.name || 'xxx'}-${
          this.currentResumeInfo.intention.length
            ? this.currentResumeInfo.intention[0].intentionName
            : '岗位'
        }-${this.currentResumeInfo.num_work_experience || ''}年/${
          this.currentResumeInfo.education_experience.length
            ? this.currentResumeInfo.education_experience[0].end_time_year
            : ''
        }届毕业`
      }
    },
    //获取文件流方法
    async toGetPdf(tile = '', download = false) {
      this.getName()
      // download：false为不下载
      this.getPdf(tile, download).then(res => {
        this.UploadPdf(res)
      })
    },
    //上传pdf接口
    async UploadPdf(res) {
      //res拿到base64的pdf
      let pdfBase64Str = res
      let title = this.resumeName
      //把简历名称存起来方便resumeFileUpyunFn接口取值
      sessionStorage.setItem('resume_title', title)
      let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
      await this.resumeFileUpyunFn(file)
      this.$router.push({
        path: '/resume/diagnosis',
        query: { ditId: this.currentResumeEditId }
      })
    },
    //将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    // 上传简历
    async resumeFileUpyunFn(file) {
      const data = new FormData()
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)
      await this.resumeFileUpyun(data)
    },
    //代表用户是从诊断简历页面跳回的 触发保存简历方法 跳回诊断简历页面
    // whetherFn() {
    //   this.resumePreview(2)
    // },
    //点击一键清空 触发组件重置方法
    resetForm() {
      this.$refs.editMeInfo.resetForm()
      this.$refs.editPerfect.resetForm()
      this.$refs.editCareerObjective.formRmove()
      this.$refs.editExperience.formRmove()
      this.$refs.editEducation.formRmove()
      this.$refs.editProject.formRmove()
      this.$refs.editTraining.formRmove()
      this.$refs.skillDecide.formRmove()
      this.$refs.honorDecide.formRmove()
      this.submitObj.self_evaluation = ''
      // if (this.skillDecide && this.obj) {
      //   this.$refs.skillDecide.formRmove()
      // } else if (this.honorDecide == 0 && this.honorDecideSet) {
      //   this.$refs.skillDecideTwo.formRmove()
      // }

      // if (this.honorDecide && this.obj) {
      //   this.$refs.honorDecide.formRmove()
      // } else if (this.honorDecide == 0 && this.honorDecideSet) {
      //   this.$refs.honorDecideTwo.formRmove()
      // }
    },
    // 技能和荣誉奖项触发 配合一键清空
    // formRmoveFn() {
    //   this.obj.skills_full = [] //接口的
    //   this.obj.awards_full = [] //接口的
    //   this.honorDecideSet = [] //本地上传的简历
    //   this.skillDecideSet = [] //本地上传的
    // },
    //选择pdf模板
    select(item) {
      this.indexOK = item.isIndex
      this.isIndex = item.isIndex
    },
    //跳转到对应的pdf页面
    ensure() {
      this.$router.push(`/resume/pdfPreview?isIndex=${this.isIndex}`)
      // const { href } = this.$router.resolve({
      //   path: `/resume/pdfPreview?isIndex=${this.isIndex}` //跳转路径
      // })
      this.drawer = false
      // window.open(href, '_blank')
    },
    //点击完善个人信息把浏览器窗口定位到当前
    distanceFn() {
      setTimeout(() => {
        let height = document.getElementById('collapse').scrollHeight
        window.scrollTo({ top: height, left: 0, behavior: 'smooth' })
      }, 400)
    },
    // 离开页面触发的方法
    async leaveFn() {
      //在每次离开页面的时候清空vuex的数据 确保回来的时候进行接口的请求获取最新数据
      await this.setCurrentResumeInfo(null)
      //在每次离开前把id默认为0
      await this.setCurrentResumeEditId(0)
      //关闭定时器
      clearTimeout(this.timers)
    }
  },
  //组件内的路由前置守卫
  async beforeRouteLeave(to, from, next) {
    await this.leaveFn()
    next()
  }
}
</script>

<style lang="stylus" scoped>
.typeArea
  position relative
  box-sizing border-box
  width 1200px
  margin 0 auto
  height auto
  >>> .el-upload-dragger
    width 731px
    height 211px
    background #F9F9F9
    box-shadow 0px 0px 10px 0px rgba(196, 214, 245, 0.15)
    border-radius 10px
    margin-bottom 29px
  .Title
    font-size 25px
    color #003dbb
    margin-top 30px
    margin-bottom 30px
    .wenhao
      display inline-block
      width 20px
      height 20px
      background-image url('~@/assets/image/resumeLogin/iconwenhao.png')
    .wenhao:hover
      cursor pointer
      background-image url('~@/assets/image/resumeLogin/iconwenhgao.png')
  .cutoffRule
    margin-top 20px
    border-bottom 1px solid #f3f3f3
  .base
    margin-top 15px
  .edit-title
    font-size 42rpx
    font-weight bold
    color rgb(253, 187, 79)
    margin-bottom 10px
  .BottomButton
    display flex
    margin-left 582px
    margin-top 30px
    margin-bottom 20px
    .add-box
      margin-right 20px
  .el-textarea
    height auto
    :deep(.el-textarea__inner)
      height 150px
  .hint
    font-size 16px
    color #B3B0AD
    line-height 38px
    margin-left 130px
    margin-bottom 22px
  .updateTime
    font-size 16px
    margin 20px 0
    display flex
    justify-content flex-end
  .box-card
    padding 20px 29px 0 30px
    width 1200px
    box-sizing border-box
    .drawer-text
      text-align center
      font-size 20px
      font-family Source Han Sans CN
      font-weight 500
      color #333333
      margin-top 0px
      margin-bottom 23px
    .drawer-list
      display flex
      .list-box
        cursor pointer
        width 197px
        height 278px
        background #FFFFFF
        border 1px solid #D1D1D1
        box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.86)
        border-radius 6px
        position relative
        margin-right 25px
        .box-baseimg
          position absolute
          bottom -2px
          right -1px
        .pdf-img
          width 100%
          height 100%
          border-radius 6px
      .list-box:hover
        border 1px solid #003DBB
      .active
        border 1px solid #003DBB
    .drawer-baseBt
      text-align center
      margin-top 40px
      span
        display inline-block
        width 110px
        height 40px
        border-radius 4px
        text-align center
        line-height 40px
        color #FFFFFF
        cursor pointer
      .ensure
        background #003DBB
        margin-right 30px
      .call
        background #FFFFFF
        border 1px solid #CCCCCC
        color #666666
.title-img
  height 228px
  margin-bottom 26px
  img
    height 100%
/deep/ .el-collapse-item__header
  font-size 25px
  color #003dbb
  margin-top 30px
/deep/ .el-upload__text
  font-size 20px
  font-family Source Han Sans CN
  font-weight 500
  color #333333
  margin-top 24px
/deep/ .el-upload__text.two
  font-size 14px
  font-family Source Han Sans CN
  font-weight 400
  color #666666
  margin-top 15px
/deep/ .el-upload__text.three
  font-size 14px
  font-family Source Han Sans CN
  font-weight 400
  color #666666
  margin-top 4px
/deep/ .el-upload-img
  margin 0 auto
  width 32px
  height 32px
  background #003DBB
  border-radius 4px
  margin-top 35px
/deep/ .el-button.el-button--default
  color #fff
  height 40px
  width 110px
  background #003DBB
  border-radius 4px
.menuStructure
  top 393px
  right 60px
  position fixed
/deep/ .el-drawer__body
  display flex
  flex-direction column
  justify-content center
  align-items center
/deep/ .el-upload-dragger:hover
  background #F1F5FF
/deep/ .el-button.el-button--default:hover
  opacity 0.8
/deep/ .el-drawer__container.el-drawer__open
  margin-top 62px
.uploadResume-box
  display flex
  .scan-box
    margin-left 19px
    width 351px
    height 211px
    background #f9f9f9
    border 1px dashed #d9d9d9
    box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.4)
    border-radius 10px
    justify-content center
    flex-direction column
    align-items center
    display flex
    .scan-img
      width 140px
      height 140px
    .scan-text
      font-size 16px
      font-family Source Han Sans CN
      font-weight 500
      color #000
      margin-top 10px
  .scan-box:hover
    background #F1F5FF
    border 1px dashed #003dbb
</style>
