<template>
  <div>
    <div class="edit-experience-wrapper" v-for="(text, index) in temporaryList" :key="index">
      <el-row type="flex">
        <el-col>
          <div class="edit-title">项目信息{{ index + 1 || '' }}</div>
        </el-col>
        <el-col :span="2">
          <el-popconfirm title="确定删除该记录信息吗" @confirm="deleteJobIntention(index)">
            <div slot="reference" class="deleteBox">
              <i class="el-icon-delete"></i>
              <span class="deletTetx">删除</span>
            </div>
          </el-popconfirm>
        </el-col>
      </el-row>
      <div class="information">
        <!-- 经历信息的表单区域 -->
        <el-form label-width="130px" :rules="rules" ref="temporary" :model="temporaryList[index]">
          <el-row type="flex">
            <el-col>
              <el-form-item label="项目名称" prop="project_name">
                <el-input placeholder="请填写您的项目名称" v-model="text.project_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="职位名称" prop="job_title">
                <el-input placeholder="请填写您的职位名称" v-model="text.job_title"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item label="工作时间" prop="start_time">
                <el-date-picker
                  type="month"
                  placeholder="请选择开始时间"
                  value-format="yyyy-MM"
                  v-model="text.start_time"
                  @change="startTime(index, text.start_time)"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <div class="soFarText">至</div>
            <el-col>
              <el-form-item prop="end_time" class="endTime">
                <el-date-picker
                  v-model="text.end_time"
                  type="month"
                  placeholder="请选择结束时间"
                  value-format="yyyy-MM"
                  @change="endTime(index, text.end_time)"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="经历描述" prop="description">
              <el-col>
                <el-input
                  type="textarea"
                  placeholder="请填写您的经历描述"
                  v-model="text.description"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
        <!-- 经历信息的表单区域 -->
      </div>
    </div>
    <div class="BottomButton">
      <div class="add-box">
        <el-button @click="onSubmit" :loading="isLoading" v-if="temporaryList.length">
          保存
        </el-button>
      </div>
      <div>
        <el-button class="add" @click="onAddExperienceFn">添加经历</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      //需要传参给后端的对象
      submitObj: {
        all_result_n: null,
        id: 0,
        project_experience: []
      },
      decide: {
        value: null,
        text: '项目经历'
      },
      Tmie: '',
      isLoading: false,
      rules: {
        project_name: [{ required: true, message: '请填写您的项目名称', trigger: 'change' }],
        start_time: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
        end_time: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
        job_title: [{ required: true, message: '请填写您的职位名称', trigger: 'change' }],
        description: [{ required: true, message: '请描述您的项目经历', trigger: 'change' }]
      },
      temporaryList: [], //项目经历信息已经存在的数组字段
      form1Valid: [] //判断是否通过兜底校验
    }
  },
  computed: {
    ...mapState(['currentResumeInfo', 'currentResumeEditId'])
  },
  created() {
    if (JSON.stringify(this.$route.query) === '{}') {
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.id) {
      this.submitObj.id = this.$route.query.id
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.type && this.$route.query.type === 'add') {
    }
  },
  methods: {
    ...mapActions(['getResumeGenerateContent', 'resumeGenerateSave']),
    async getResumeGenerateContentFn() {
      if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
      const obj = await this.getResumeGenerateContent({
        id: this.submitObj.id
      })
      this.returnInfoFn(obj)
    },
    //工作时间开始选择
    startTime(index, time) {
      if (time !== null) {
        time = time.split('-')
        this.temporaryList[index].start_time_year = time[0]
        this.temporaryList[index].start_time_month = time[1]
        this.temporaryList[index].start_time_startDate = `${time[0]}.${time[1]}`
      }
    },
    //工作时间结束选择
    endTime(index, time) {
      if (time !== null) {
        time = time.split('-')
        this.temporaryList[index].end_time_year = time[0]
        this.temporaryList[index].end_time_month = time[1]
        this.temporaryList[index].start_time_endDate = `${time[0]}.${time[1]}`
      }
    },
    //删除当前经历信息
    deleteJobIntention(index) {
      this.temporaryList.splice(index, 1)
      this.onSubmitFn()
    },
    //添加项目经历
    onAddExperienceFn() {
      this.temporaryList.push({
        subject: '',
        organization_name: '',
        job_title: '',
        school_name: '',
        project_name: '',
        major: '',
        degree: '',
        start_time: '',
        start_time_month: '',
        start_time_year: '',
        end_time: '',
        end_time_month: '',
        end_time_year: '',
        description: ''
      })
    },
    //保存方法
    async onSubmitFn() {
      this.isLoading = true
      if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
      this.submitObj.project_experience = this.temporaryList
      const res = await this.resumeGenerateSave(this.submitObj)
      if (res.code === 200) {
        this.$message({
          message: '经历信息保存成功',
          type: 'success'
        })
        // this.getResumeGenerateContentFn()
      } else {
        this.$message.error('保存失败,请重试')
      }
      this.isLoading = false
    },
    //保存按钮
    async onSubmit() {
      this.form1Valid = []
      if (this.temporaryList.length) {
        this.$nextTick(async () => {
          this.$refs.temporary.forEach((item, index) => {
            this.$refs.temporary[index].validate(valid => {
              if (valid) {
                this.form1Valid.push(valid)
              } else {
                this.form1Valid.push(valid)
              }
            })
          })
          // form表单全部通过校验
          if (!this.form1Valid.includes(false)) {
            // form表单全部通过校验
            if (!this.form1Valid.includes(false)) {
              for (let i = 0; i < this.temporaryList.length; i++) {
                if (
                  this.temporaryList[i].end_time.split('-').join('') <=
                  this.temporaryList[i].start_time.split('-').join('')
                ) {
                  return this.$message({
                    message: `项目信息${i + 1}的工作结束时间要大于开始时间!`,
                    type: 'warning'
                  })
                }
              }
            }
            this.onSubmitFn()
          }
        })
      }
    },
    //点击预览简历触发校验
    onSubmitOk() {
      this.form1Valid = []
      if (this.temporaryList.length) {
        this.$nextTick(async () => {
          this.$refs.temporary.forEach((item, index) => {
            this.$refs.temporary[index].validate(valid => {
              if (valid) {
                this.form1Valid.push(valid)
              } else {
                this.form1Valid.push(valid)
              }
            })
          }) // form表单全部通过校验
          if (!this.form1Valid.includes(false)) {
            this.decide.value = true
          } else {
            this.decide.value = false
          }
        })
      } else {
        this.decide.value = true
      }
    },
    //回显方法
    returnInfoFn(obj) {
      this.submitObj.id = obj && obj.id ? obj.id : 0
      if (obj) {
        // 接口正常回调
        if (obj.project_experience && obj.project_experience.length) {
          obj.project_experience.forEach(item => {
            item.start_time = item.start_time_year.length
              ? `${item.start_time_year}-${item.start_time_month}`
              : ''
            item.end_time = item.end_time_year.length
              ? `${item.end_time_year}-${item.end_time_month}`
              : ''
          })
          this.temporaryList = obj.project_experience
          this.temporaryList.forEach(item => {
            if (item.start_time && item.start_time.length) {
              const time = item.start_time.split('-')
              item.start_time_startDate = `${time[0]}.${time[1]}`
            }
            if (item.end_time && item.end_time.length) {
              const time = item.end_time.split('-')
              item.start_time_endDate = `${time[0]}.${time[1]}`
            }
          })
        }
        // 简历解析
        if (obj.parsing_result) {
          // 项目经历判断
          if (
            obj.parsing_result.project_experience &&
            obj.parsing_result.project_experience.length
          ) {
            this.temporaryList = []
            obj.parsing_result.project_experience.forEach(item => {
              item.start_time = item.start_time_year.length
                ? `${item.start_time_year}-${item.start_time_month}`
                : ''
              item.end_time = item.end_time_year.length
                ? `${item.end_time_year}-${item.end_time_month}`
                : ''
              if (item.start_time && item.start_time.length) {
                const time = item.start_time.split('-')
                item.start_time_startDate = `${time[0]}.${time[1]}`
              }
              if (item.end_time && item.end_time.length) {
                const time = item.end_time.split('-')
                item.start_time_endDate = `${time[0]}.${time[1]}`
              }
            })
            this.temporaryList = JSON.parse(JSON.stringify(obj.parsing_result.project_experience))
          } else {
            this.temporaryList = []
          }
        }
      }
    },
    //点击一键清空触发
    formRmove() {
      this.temporaryList = []
    }
  },
  watch: {
    currentResumeInfo(obj) {
      this.returnInfoFn(obj)
    }
  }
}
</script>

<style lang="stylus" scoped>
.edit-experience-wrapper
  .edit-title
    margin-bottom 10px
    font-size 16px
    font-family Source Han Sans CN
    font-weight bold
    color #333333
  .information
    margin-top 8px
  .el-textarea
    height auto
    :deep(.el-textarea__inner)
      height 100px
  .soFarText
    font-size 14px
    color #606266
    line-height 40px
    margin-left -350px
  >>> .el-form-item.endTime
    margin-left -105px
.BottomButton
  display flex
  margin-left 560px
  margin-top 30px
  .add-box
    margin-right 20px
/deep/ .el-button.el-button--default
  color #fff
  height 40px
  width 110px
  background #003DBB
  border-radius 4px
/deep/ .el-button.add.el-button--default
  width 110px
  height 40px
  background #FFFFFF
  border 1px solid #003DBB
  border-radius 4px
  font-family Source Han Sans CN
  font-weight 400
  color #1c52c2
  text-align center
/deep/ .el-button.el-button--default:hover
  opacity 0.8
.deleteBox
  font-size 14px
  font-family Source Han Sans CN
  font-weight bold
  color #F56C6C
  cursor pointer
  .deletTetx
    margin-left 6px
  .deletTetx:hover
    border-bottom 1px solid #F56C6C
</style>
