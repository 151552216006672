<template>
  <div>
    <el-form label-width="130px">
      <el-row type="flex">
        <el-col>
          <el-form-item label="求职状态">
            <el-select v-model="submitObj.current_status" placeholder="请选择求职状态">
              <el-option
                v-for="item in currentStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                @click.native="currentStatusClick(item)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="参加工作时间">
            <el-date-picker
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              v-model="submitObj.work_start_year"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item label="工作年限">
            <el-input placeholder="请填写您的工作年限" v-model="submitObj.num_work_experience">
              <span slot="suffix">/年</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="当前所属行业" v-if="configObj && configObj.intention_trade">
            <el-select v-model="submitObj.industry" placeholder="请选择当前所属行业">
              <el-option
                v-for="item in configObj.intention_trade"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="intentionTradeClick(item)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item label="当前公司">
            <el-input
              placeholder="请填写您的当前公司"
              v-model="submitObj.current_company"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="当前职位">
            <el-input
              placeholder="请填写您的当前职位"
              v-model="submitObj.current_position"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item label="当前薪资">
            <el-input placeholder="请填写您的当前薪资" v-model="submitObj.current_salary">
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col></el-col>
      </el-row>
      <div class="BottomButton">
        <div class="add-box">
          <el-button @click="onSubmit" :loading="isLoading">保存</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      isLoading: false,
      jobDecide: 1, //0显示用户本地上传的简历信息 1显示已经诊断过的简历信息
      // 就业信息字段
      submitObj: {},
      // 简历生成配置信息
      configObj: null,
      //求职状态
      currentStatusList: [
        {
          label: '在职，正在找工作',
          value: 0
        },
        {
          label: '在职，考虑好的职业机会',
          value: 1
        },
        {
          label: '在职，暂不考虑其他机会',
          value: 2
        },
        {
          label: '已离职',
          value: 3
        },
        {
          label: '应届生',
          value: 4
        }
      ]
    }
  },
  computed: {
    ...mapState(['resumeAnalysisUserInfo'])
  },
  created() {
    this.resumeAnalysisUserInfo != null ? (this.jobDecide = 0) : (this.jobDecide = 1)
    this.getResumeGenerateConfigFn()
    this.getResumeGenerateContentFn()
  },
  methods: {
    ...mapActions(['getResumeGenerateContent', 'resumeGenerateSave', 'getResumeGenerateConfig']),
    async getResumeGenerateContentFn() {
      const obj = await this.getResumeGenerateContent({ noLoading: false })
      if (obj.id) {
        this.submitObj = {
          // 求职状态 在职，正在找工作/在职，考虑好的职业机会/在职，暂不考虑其他机会/已离职/应届生
          current_status: this.jobDecide
            ? obj.current_status
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.current_status || '',
          // 开始工作年限四位数年份，如2013
          work_start_year: this.jobDecide
            ? obj.work_start_year
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.work_start_year || '',
          // 工作年限：
          num_work_experience: this.jobDecide
            ? obj.num_work_experience === 0
              ? ''
              : obj.num_work_experience
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.num_work_experience === 0
            ? ''
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.num_work_experience,
          // 所属行业
          industry: this.jobDecide
            ? obj.industry
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.industry || '',
          // 当前公司
          current_company: this.jobDecide
            ? obj.current_company
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.current_company || '',
          // 当前职位
          current_position: this.jobDecide
            ? obj.current_position
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.current_position || '',
          // 当前薪资
          current_salary: this.jobDecide
            ? obj.current_salary
            : this.resumeAnalysisUserInfo.parsing_result.basic_info.current_salary || ''
        }
      }
    },
    // 简历生成配置信息
    async getResumeGenerateConfigFn() {
      this.configObj = await this.getResumeGenerateConfig()
    },
    //当前所属行业选择
    intentionTradeClick(item) {
      this.submitObj.industry = item.name
    },
    // 求职状态选择
    currentStatusClick(item) {
      this.submitObj.current_status = item.label
    },
    // 保存按钮
    async onSubmit() {
      this.jobDecide = 1
      this.isLoading = true
      const all_result_n = null
      this.submitObj = Object.assign(this.submitObj, { all_result_n })
      const res = await this.resumeGenerateSave(this.submitObj)
      if (res.code === 200) {
        this.$message({
          message: '就业信息保存成功',
          type: 'success'
        })
        this.getResumeGenerateContentFn()
      } else {
        this.$message.error('保存失败,请重试')
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.BottomButton
  display flex
  margin-left 560px
  margin-top 30px
  .add-box
    margin-right 20px
/deep/ .el-button.empty.el-button--warning
  margin-left 20px
/deep/ .el-button.el-button--default
  color #fff
  height 40px
  width 110px
  background #003DBB
  border-radius 4px
</style>
