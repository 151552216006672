<template>
  <div>
    <div class="edit-experience-wrapper" v-for="(text, index) in temporaryListBoxList" :key="index">
      <el-row type="flex">
        <el-col>
          <div class="edit-title">技能信息{{ index + 1 || '' }}</div>
        </el-col>
        <el-col :span="2">
          <!-- <el-popconfirm
            title="确定删除该记录信息吗"
            @confirm="deleteJobIntention(index)"
            v-if="temporaryList.length > 1 && skill != 1"
          >
            <el-button slot="reference" type="danger" icon="el-icon-delete">删除</el-button>
          </el-popconfirm> -->
          <div class="deleteBox" @click="deleteJobIntention(index)">
            <i class="el-icon-delete"></i>
            <span class="deletTetx">删除</span>
          </div>
          <!-- <el-button type="danger" icon="el-icon-delete" @click="deleteJobIntention(index)">删除</el-button> -->
        </el-col>
      </el-row>
      <div class="information">
        <!-- 经历信息的表单区域 -->
        <el-form
          label-width="130px"
          :rules="rules"
          ref="temporary"
          :model="temporaryListBoxList[index]"
        >
          <el-row type="flex">
            <el-col>
              <el-form-item label="技能名称" prop="name">
                <el-input placeholder="请填写您的技能名称" v-model="text.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="掌握程度" prop="level">
                <el-select v-model="text.level" placeholder="请选择">
                  <el-option
                    v-for="item in levelList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    @click.native="levelClick(index, item)"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item label="掌握时长" prop="time">
                <el-input
                  placeholder="单位：月/请填写"
                  v-model="text.time"
                  type="number"
                  class="unrule"
                >
                  <span slot="suffix">月</span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col></el-col>
          </el-row>
          <el-row>
            <el-form-item label="技能描述" prop="description">
              <el-col>
                <el-input
                  type="textarea"
                  placeholder="请对您的技能进行描述"
                  v-model="text.description"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
        <!-- 经历信息的表单区域 -->
      </div>
    </div>
    <div class="BottomButton">
      <div class="add-box" v-if="temporaryListBoxList.length">
        <el-button @click="onSubmit" :loading="isLoading">保存</el-button>
      </div>
      <div>
        <el-button class="add" @click="onAddExperienceFn">添加技能</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      temporaryListBoxList: [],
      submitObj: {
        all_result_n: null,
        id: 0,
        skills_full: []
      },
      decide: {
        value: null,
        text: '技能信息'
      },
      isLoading: false,
      levelList: [
        {
          label: '一般',
          value: 0
        },
        {
          label: '良好',
          value: 1
        },
        {
          label: '精通',
          value: 2
        }
      ],
      rules: {
        name: [{ required: true, message: '请填写您的技能名称', trigger: 'change' }],
        level: [{ required: true, message: '请选择掌握程度', trigger: 'change' }],
        time: [{ required: true, message: '请填写您的掌握时长', trigger: 'change' }],
        description: [{ required: true, message: '请描述您的项目经历', trigger: 'change' }]
      },
      form1Valid: [] //判断是否通过兜底校验
    }
  },
  created() {
    this.listFn()
  },
  computed: {
    ...mapState(['currentResumeEditId', 'currentResumeInfo'])
  },
  methods: {
    ...mapActions(['resumeGenerateSave']),

    //掌握程度选择
    levelClick(index, item) {
      this.temporaryListBoxList[index].level = item.label
    },
    //删除当前经历信息
    deleteJobIntention(index) {
      this.temporaryListBoxList.splice(index, 1)
      this.onSubmitFn()
    },
    //添加技能数组
    onAddExperienceFn() {
      this.temporaryListBoxList.push({
        description: '', //技能描述
        level: '', //掌握程度
        name: '', //名字
        time: '' //掌握时长
      })
    },
    //保存方法
    async onSubmitFn() {
      this.isLoading = true
      if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
      this.submitObj.skills_full = this.temporaryListBoxList

      const res = await this.resumeGenerateSave(this.submitObj)
      if (res.code === 200) {
        this.$message({
          message: '技能信息保存成功',
          type: 'success'
        })
      } else {
        this.$message.error('保存失败,请重试')
      }
      this.isLoading = false
    },
    //保存按钮
    async onSubmit() {
      this.form1Valid = []
      if (this.temporaryListBoxList.length) {
        this.$nextTick(async () => {
          this.$refs.temporary.forEach((item, index) => {
            this.$refs.temporary[index].validate(valid => {
              if (valid) {
                this.form1Valid.push(valid)
              } else {
                this.form1Valid.push(valid)
              }
            })
          })
          // form表单全部通过校验
          if (!this.form1Valid.includes(false)) {
            this.onSubmitFn()
          }
        })
      }
    },
    //点击预览触发校验
    onSubmitOk() {
      this.form1Valid = []
      if (this.temporaryListBoxList.length) {
        this.$nextTick(async () => {
          this.$refs.temporary.forEach((item, index) => {
            this.$refs.temporary[index].validate(valid => {
              if (valid) {
                this.form1Valid.push(valid)
              } else {
                this.form1Valid.push(valid)
              }
            })
          }) // form表单全部通过校验
          if (!this.form1Valid.includes(false)) {
            this.decide.value = true
          } else {
            this.decide.value = false
          }
        })
      } else {
        this.decide.value = true
      }
    },
    //点击一键清空触发
    formRmove() {
      this.temporaryListBoxList = []
      // this.$emit('formRmoveFn')
    },
    // 处理list数据
    listFn() {
      if (this.currentResumeInfo) {
        if (this.currentResumeInfo.skills_full && this.currentResumeInfo.skills_full.length) {
          this.temporaryListBoxList = this.currentResumeInfo.skills_full
        } else if (
          this.currentResumeInfo.parsing_result &&
          this.currentResumeInfo.parsing_result.others &&
          this.currentResumeInfo.parsing_result.others.skills &&
          this.currentResumeInfo.parsing_result.others.skills.length
        ) {
          let list = []
          this.currentResumeInfo.parsing_result.others.skills.forEach(item => {
            list.push({
              name: item,
              level: '',
              time: '',
              description: ''
            })
          })
          this.temporaryListBoxList = list
        } else {
          this.temporaryListBoxList = []
        }
      }
    }
  },
  watch: {
    currentResumeInfo() {
      this.listFn()
    }
  }
}
</script>

<style lang="stylus" scoped>
.edit-experience-wrapper
  .edit-title
    margin-bottom 10px
    font-size 16px
    font-family Source Han Sans CN
    font-weight bold
    color #333333
  .information
    margin-top 8px
  .el-textarea
    height auto
    :deep(.el-textarea__inner)
      height 100px
  .soFarText
    font-size 14px
    color #606266
    line-height 40px
    margin-left -432px
  >>> .el-form-item.endTime
    margin-left -105px
.BottomButton
  display flex
  margin-left 582px
  margin-top 30px
  .add-box
    margin-right 20px
/deep/ .el-button.el-button--default
  color #fff
  height 40px
  width 110px
  background #003DBB
  border-radius 4px
/deep/ .el-button.add.el-button--default
  width 110px
  height 40px
  background #FFFFFF
  border 1px solid #003DBB
  border-radius 4px
  font-family Source Han Sans CN
  font-weight 400
  color #1c52c2
  text-align center
/deep/ .el-button.el-button--default:hover
  opacity 0.8
.deleteBox
  font-size 14px
  font-family Source Han Sans CN
  font-weight bold
  color #F56C6C
  cursor pointer
  .deletTetx
    margin-left 6px
  .deletTetx:hover
    border-bottom 1px solid #F56C6C
</style>
