<template>
  <div>
    <div class="edit-experience-wrapper" v-for="(text, index) in temporaryList" :key="index">
      <el-row type="flex">
        <el-col>
          <!-- <div class="edit-title">求职意向{{ index + 1 || '' }}</div> -->
        </el-col>
        <el-col :span="2">
          <el-popconfirm title="确定删除该求职意向吗？" @confirm="deleteJobIntention(index)">
            <div slot="reference" class="deleteBox">
              <i class="el-icon-delete"></i>
              <span class="deletTetx">删除</span>
            </div>
          </el-popconfirm>
        </el-col>
      </el-row>
      <!-- 求职意向的表单区域 -->
      <el-form label-width="130px" ref="jobIntention" :model="temporaryList[index]" :rules="rules">
        <el-row type="flex">
          <el-col>
            <el-form-item label="工作性质" prop="nature_name">
              <el-select placeholder="请选择您的工作性质" v-model="text.nature_name">
                <el-option
                  ref="natureSelect"
                  v-for="item in natureList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  @click.native="natureChange(index, item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="期望职位"
              v-if="configObj && configObj.intention_job"
              prop="category_name"
            >
              <el-cascader
                v-model="categoryNameData[index]"
                ref="categoryName"
                @visible-change="categoryNameChange(index)"
                :placeholder="text.category_name ? text.category_name : '请选择期望职位'"
                :options="configObj.intention_job"
                :props="customProps"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col>
            <el-form-item label="工作地区" prop="city">
              <el-cascader
                ref="city"
                :placeholder="text.city ? text.city : '请选择期望工作地区'"
                :options="cityList"
                v-model="cityData[index]"
                @visible-change="cityChange(index)"
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-row type="flex">
              <el-col>
                <el-form-item label="期望薪资" prop="minwage_name">
                  <el-select v-model="text.minwage_name" placeholder="请选择">
                    <el-option
                      v-for="item in minwageList[index]"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      @click.native="minwageName(index, item)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <div class="soFarText" v-if="minwageValueShow[index] !== 0">至</div>
              <el-col v-if="minwageValueShow[index] !== 0">
                <el-form-item prop="maxwage_name" class="maxwage_name">
                  <el-select v-model="text.maxwage_name" placeholder="请选择" class="soFar">
                    <el-option
                      v-for="item in maxwageList[index]"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      @click.native="maxwageName(index, item)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col>
            <el-form-item
              label="期望行业"
              v-if="configObj && configObj.intention_trade"
              prop="trade_name"
            >
              <el-select v-model="text.trade_name" placeholder="请选择">
                <el-option
                  v-for="item in configObj.intention_trade"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                  @click.native="intentionTrade(index, item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="意向岗位" prop="intentionName">
              <el-autocomplete
                value-key="name"
                class="inline-input"
                v-model="text.intentionName"
                :fetch-suggestions="querySearch"
                placeholder="请输入关键字"
                @select="handleSelect"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 求职意向的表单区域 -->
    </div>
    <div class="BottomButton">
      <div class="add-box">
        <el-button @click="onSubmit" v-if="temporaryList.length" :loading="isLoading">
          保存
        </el-button>
      </div>
      <div>
        <el-button class="add" @click="onAddExperienceFn" v-if="temporaryList.length <= 0">
          添加意向
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      // 传给后端的对象
      submitObj: {
        all_result_n: null,
        id: 0,
        intention: []
      },
      restaurants: [], //求职意向列表
      minwageValue: [], //记录用户当选中的最低薪资
      minwageValueIndex: 0, //记录用户当前选择的下标
      minwageValueShow: [], //如果为面议则后面至多就不显示
      decide: {
        value: null,
        text: '求职意向'
      },
      isLoading: false,
      // jobIntentionDecide: 1, //0显示用户本地上传的简历信息 1显示已经诊断过的简历信息
      rules: {
        nature_name: [{ required: true, message: '请选择工作性质', trigger: 'change' }],
        category_name: [
          { required: true, message: '请选择期望职位', trigger: 'change' }
          // { validator: validaCategoryName, trigger: 'change' }
        ],
        city: [{ required: true, message: '请选择工作地区', trigger: 'change' }],
        minwage_name: [{ required: true, message: '请选择薪资', trigger: 'change' }],
        maxwage_name: [{ required: true, message: '请选择薪资', trigger: 'change' }],
        trade_name: [{ required: true, message: '请选择期望行业', trigger: 'change' }],
        intentionName: [{ required: true, message: '请选择期望行业', trigger: 'change' }]
      },
      form1Valid: [], //判断是否通过兜底校验
      temporaryList: [], //已经存在得求职意向数组
      configObj: null, //配置信息
      cityList: [], // 省市区列表
      cityData: [], //已经存在得省市区id(进行回显)
      categoryNameData: [], //已经存在得期望职位id(进行回显)
      customProps: {
        checkStrictly: true,
        label: 'text' // 自定义当前数组的键名 - label
      },
      //工作性质
      natureList: [
        {
          label: '全职',
          value: 1
        },
        {
          label: '兼职',
          value: 2
        },
        {
          label: '实习',
          value: 3
        }
      ],
      // 最低薪资列表
      minwageList: [],
      // 最高薪资薪资列表
      maxwageList: []
    }
  },
  async created() {
    if (JSON.stringify(this.$route.query) === '{}') {
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.id) {
      this.submitObj.id = this.$route.query.id
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.type && this.$route.query.type === 'add') {
    }
    this.getThreeAgreementAreaListFn()
    this.getResumeGenerateConfigFn()
    this.getResumeDiagnosisPositionListFn()
  },
  computed: {
    ...mapState(['currentResumeEditId', 'currentResumeInfo'])
  },
  methods: {
    ...mapActions([
      'getResumeGenerateContent',
      'getResumeGenerateConfig',
      'getThreeAgreementAreaList',
      'resumeGenerateSave',
      'getResumeDiagnosisPositionList'
    ]),
    // 生成期望薪资的最低薪资
    minwageListFn() {
      const minOne = [
        {
          label: '面议',
          value: '0'
        }
      ]
      for (let i = 1; i <= 250; i++) {
        const label = `${i}k`
        const value = `${i}`
        minOne.push({ label, value })
      }
      this.minwageList.push(minOne)
    },
    // 生成期望薪资的最高薪资
    maxwageListFn() {
      this.minwageValue = this.minwageValue.map(item => Number(item) + 1)
      const maxOne = []
      for (let i = this.minwageValue[this.minwageValueIndex]; i <= 250; i++) {
        const label = `${i}k`
        const value = `${i}`
        maxOne.push({ label, value })
      }
      this.maxwageList.splice(this.minwageValueIndex, 1, maxOne)
    },
    async getResumeGenerateContentFn() {
      if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
      const obj = await this.getResumeGenerateContent({ id: this.submitObj.id })
      if (obj && obj.id) {
        this.submitObj.id = obj.id
        if (obj.intention.length) {
          if (!obj.intention[0].intentionName) {
            obj.intentionName = ''
            obj.intentionId = ''
          }
        }
        this.returnInfoFn(obj)
      }
    },
    // 简历生成配置信息
    async getResumeGenerateConfigFn() {
      this.configObj = await this.getResumeGenerateConfig()
    },
    // 动态获取省市区
    async getThreeAgreementAreaListFn() {
      const res = await this.getThreeAgreementAreaList()
      this.cityList = res.area_data
    },
    //处理薪资的方法
    salaryRangeFn() {
      if (this.temporaryList.length) {
        this.temporaryList.forEach(item => {
          if (item.minwage_name !== '面议' && item.minwage_name !== undefined) {
            this.minwageValue.push(parseInt(item.minwage_name.replace('k', '')))
            this.minwageValueShow.push(parseInt(item.minwage_name.replace('k', '')))
          } else {
            this.minwageValue.push(parseInt(0))
            this.minwageValueShow.push(parseInt(0))
          }
          this.minwageListFn()
          this.maxwageListFn()
          this.cityData.push([item.district1, item.district2, item.district3])
          this.categoryNameData.push([
            Number(item.category1),
            Number(item.category2),
            Number(item.category3)
          ])
          item.nature = parseInt(item.nature)
        })
      }
    },
    // 搜索建议的显示
    async querySearch(queryString, cb) {
      await this.getResumeDiagnosisPositionListFn()
      var restaurants = this.restaurants
      var results = restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    //点击筛选过的值
    handleSelect(item) {
      this.temporaryList[0].intentionName = item.name
      this.temporaryList[0].intentionId = item.id
    },
    // 岗位列表
    async getResumeDiagnosisPositionListFn() {
      this.restaurants = []
      this.restaurants = await this.getResumeDiagnosisPositionList({
        search:
          this.temporaryList[0] && this.temporaryList[0].intentionName
            ? this.temporaryList[0].intentionName
            : ''
      })
    },
    //工作性质选择
    natureChange(index, item) {
      this.temporaryList[index].nature_name = item.label
      this.temporaryList[index].nature = parseInt(item.value)
    },

    //期望职位选择
    categoryNameChange(index) {
      if (
        this.$refs.categoryName[index].getCheckedNodes().length &&
        this.$refs.categoryName[index].getCheckedNodes()[0] !== null
      ) {
        let res = this.$refs.categoryName[index].getCheckedNodes()
        if (res[0].path.length == 1) {
          this.temporaryList[index].category1 = res[0].path[0].toString()
        } else if (res[0].path.length == 2) {
          this.temporaryList[index].category1 = res[0].path[0].toString()
          this.temporaryList[index].category2 = res[0].path[1].toString()
        } else if (res[0].path.length == 3) {
          this.temporaryList[index].category1 = res[0].path[0].toString()
          this.temporaryList[index].category2 = res[0].path[1].toString()
          this.temporaryList[index].category3 = res[0].path[2].toString()
        }
        this.temporaryList[index].category_name = res[0].pathLabels.join('')
        this.temporaryList[index].category_label = res[0].label
        this.$nextTick(() => [this.$refs.jobIntention[index].validateField('category_name')])
      }
    },
    //工作地区选择
    cityChange(index) {
      if (this.$refs.city[index].getCheckedNodes()[0] !== null) {
        if (this.$refs.city[index].getCheckedNodes().length) {
          let res = this.$refs.city[index].getCheckedNodes()
          this.temporaryList[index].district1 = res[0].path[0]
          this.temporaryList[index].district2 = res[0].path[1]
          this.temporaryList[index].district3 = res[0].path[2]
          this.temporaryList[index].city = res[0].pathLabels.join('')
        }
      }
    },
    //期望薪资最低选择
    minwageName(index, item) {
      this.temporaryList[index].minwage_name = item.label
      this.temporaryList[index].minwage = item.value.toString()

      this.minwageValue[index] = item.value.toString()
      this.minwageValueShow[index] = Number(item.value)

      if (this.minwageValueShow[index] === 0) {
        this.temporaryList[index].maxwage_name = 0
        this.temporaryList[index].maxwage = 0
      } else {
        const maxwageNumber = Number(item.label.replace('k', '')) + 1
        this.temporaryList[index].maxwage_name = maxwageNumber + 'k'
        this.temporaryList[index].maxwage = maxwageNumber.toString()
      }
      this.minwageValueIndex = index
      this.maxwageListFn()
    },
    //期望薪资最高选择
    maxwageName(index, item) {
      // this.temporaryList[index].maxwage_name = item.label
      // this.temporaryList[index].maxwage = item.value.toString()
      this.$set(this.temporaryList, index, {
        ...this.temporaryList[index],
        maxwage_name: item.label,
        maxwage: item.value.toString()
      })
      // this.$forceUpdate()
    },
    //期望行业选择
    intentionTrade(index, item) {
      this.temporaryList[index].trade = item.id.toString()
      this.temporaryList[index].trade_name = item.name
    },
    //删除当前求职意向
    deleteJobIntention(index) {
      // if (this.temporaryList.length == 1) {
      //   this.$message({
      //     message: '请至少添加一项求职意向',
      //     type: 'warning'
      //   })
      // } else {
      // }
      this.minwageList.splice(index, 1)
      this.cityData.splice(index, 1)
      this.categoryNameData.splice(index, 1)
      this.temporaryList.splice(index, 1)
      // this.decide.value = true
      this.onSubmitFn()
    },
    //添加求职意向
    onAddExperienceFn() {
      this.temporaryList.push({
        nature: '', //工作性质
        nature_name: '', //工作性质名称
        district1: '', //省
        district2: '', //市
        district3: '', //区
        city: '', //省市区拼接
        category1: '', //职位1
        category2: '', //职位2
        category3: '', //职位3
        category_name: '', //职位名称
        minwage: '', //最低薪资
        maxwage: '', //最高薪资
        trade: '', //行业
        trade_name: '' //行业，名称
      })
      this.minwageListFn()
      this.minwageValue = []
      this.minwageValue.push('0')
      this.maxwageListFn()
    },
    //保存方法
    async onSubmitFn() {
      this.isLoading = true
      if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
      this.submitObj.intention = this.temporaryList
      const res = await this.resumeGenerateSave(this.submitObj)
      if (res.code == 200) {
        this.$message({
          message: '求职意向保存成功',
          type: 'success'
        })
      } else {
        this.$message.error('保存失败,请重试')
      }
      this.isLoading = false
    },
    //保存按钮
    async onSubmit() {
      this.form1Valid = []
      if (this.temporaryList.length) {
        this.$nextTick(async () => {
          this.$refs.jobIntention.forEach((item, index) => {
            this.$refs.jobIntention[index].validate(valid => {
              if (valid) {
                this.form1Valid.push(valid)
              } else {
                this.form1Valid.push(valid)
              }
            })
          })
          if (!this.form1Valid.includes(false)) {
            this.onSubmitFn()
          }
        })
      }
    },
    //点击预览简历触发校验
    onSubmitOk() {
      this.form1Valid = []
      if (this.temporaryList.length) {
        this.$nextTick(async () => {
          this.$refs.jobIntention.forEach((item, index) => {
            this.$refs.jobIntention[index].validate(valid => {
              if (valid) {
                this.form1Valid.push(valid)
              } else {
                this.form1Valid.push(valid)
              }
            })
          })
          if (!this.form1Valid.includes(false)) {
            return (this.decide.value = true)
          } else {
            return (this.decide.value = false)
          }
        })
      } else {
        this.decide.value = true
      }
    },
    //点击一键清空触发
    formRmove() {
      //工作地区清空
      this.cityData = []
      this.categoryNameData = []
      this.temporaryList = []
    },
    //回显方法
    async returnInfoFn(obj) {
      //期望职位清空
      this.categoryNameData = []
      //工作地区清空
      this.cityData = []
      this.temporaryList = []
      if (obj && obj.intention && obj.intention.length) this.temporaryList = obj.intention
      this.salaryRangeFn()
    }
  },
  watch: {
    // 当监听到用户上传简历分析的数据变化时候触发
    currentResumeInfo(obj) {
      this.returnInfoFn(obj)
    }
  }
}
</script>

<style lang="stylus" scoped>
.edit-experience-wrapper
  .edit-title
    font-size 42rpx
    font-weight bold
    color rgb(253, 187, 79)
    margin-bottom 10px
  .soFarText
    font-size 14px
    color #606266
    line-height 40px
    margin-left 25px
  >>>.el-select.soFar
    margin-left -83px
  >>>.el-form-item.maxwage_name.is-required
    margin-left -20px
.BottomButton
  display flex
  margin-left 567px
  margin-top 30px
  .add-box
    margin-right 12px
/deep/ .el-button.el-button--default
  color #fff
  height 40px
  width 110px
  background #003DBB
  border-radius 4px
/deep/ .el-button.add.el-button--default
  width 110px
  height 40px
  background #FFFFFF
  border 1px solid #003DBB
  border-radius 4px
  font-family Source Han Sans CN
  font-weight 400
  color #1c52c2
  text-align center
/deep/ .el-button.el-button--default:hover
  opacity 0.8
.deleteBox
  font-size 14px
  font-family Source Han Sans CN
  font-weight bold
  color #F56C6C
  cursor pointer
  .deletTetx
    margin-left 6px
  .deletTetx:hover
    border-bottom 1px solid #F56C6C
/deep/ .el-cascader
  .el-input__inner::placeholder
    color #606266
</style>
